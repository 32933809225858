import bgImage from "./assets/landing-bg.webp";
import comingImage from "./assets/coming-soon.png";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";
import CrownModel from "./crown/crown";

export const LandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center relative overflow-hidden">
      <div
        className="absolute w-full h-full -m-2 blur-sm scale-110"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="absolute w-full h-full bg-black opacity-60"></div>

      <div className="z-20 flex flex-col items-center justify-center relative select-none">
        <div className="relative">
          <h1 className="text-8xl text-white py-2 px-4 rounded font-black relative z-30">
            KINGS LEAGUE TODAY
          </h1>
          <h1 className="text-8xl text-[#AF2537] py-2 px-4 rounded font-black absolute top-[5px] right-[5px] z-20 whitespace-nowrap opacity-30">
            KINGS LEAGUE TODAY
          </h1>
        </div>

        <p className="text-white mt-4 px-4 py-2 rounded uppercase z-30 font-bold">
          Home for all the information youu need about the Kings World
        </p>
        <img
          src={comingImage}
          alt="Coming Soon"
          className="mt-8 absolute -bottom-[175px]"
          style={{ width: "200px" }}
        />
        <div className="absolute w-[400px] h-[400px] -top-[250px]">
          <Canvas className="h-full w-full" shadows>
            <Stage environment="city" intensity={0.6}>
              <CrownModel />
              <OrbitControls
                makeDefault
                minPolarAngle={Math.PI / 2}
                maxPolarAngle={Math.PI / 2}
                autoRotate
                autoRotateSpeed={0.5}
              />
            </Stage>
          </Canvas>
        </div>
      </div>
    </div>
  );
};
