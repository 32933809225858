/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/golden_crown.glb 
Author: Pieter Ferreira (https://sketchfab.com/Badboy17Aiden)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/golden-crown-5689a9525a2c425d93f7d483b93d8cfa
Title: Golden crown
*/
import { useGLTF } from "@react-three/drei";

export default function CrownModel() {
  const { nodes, materials } = useGLTF("/golden_crown.glb");
  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.Object_2.geometry}
        material={materials.None}
        rotation={[-Math.PI / 1.55, 0.15, 0]}
      />
    </group>
  );
}

useGLTF.preload("/golden_crown.glb");
